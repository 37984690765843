@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  box-sizing: border-box;
}

.outfit-text {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: white;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.App {
  text-align: center;
}

.App-logo {
  display: block;
  height: 30vmin;
  pointer-events: none;
  max-width: 80%;
  margin: 0 auto;
}

.truckImage {
  display: block;
  height: 50vmin;
  pointer-events: none;
  max-width: 90%;
  margin: 0 auto;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.33em;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.App-header p {
  max-width: 632px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

.bookingButton {
  background-color: #1cadeb;
  color: #ffffff;
  border: none;
  border-radius: 7px;
  padding: 10px 20px;
  text-decoration: none;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

a.phoneNumber {
  color: #61dafb;
  /* text-decoration: none; */
}

.mt-10 {
  margin-top: 6rem;
}

.text-faded {
  font-size: 0.85em;
  color: #a9abae;
}

.textCaption {
  font-size: .7em;
  color: #788397;
}